import {defineStore} from 'pinia';
import {axios} from "../utils";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
export const useCsrfStore = defineStore('csrfStore', {
    state: () => ({
        formulaire_csrf_token: null,
        admin_csrf_token: null,
        user_csrf_token: null,
        pos_csrf_token: null,
    }),
    actions: {
        initFormulaireCsrf() {
            if(document.querySelector('meta[name="formulaire-csrf"]').getAttribute('content')) {
                localStorage.setItem("formulaire_csrf_token", document.querySelector('meta[name="formulaire-csrf"]').getAttribute('content'));
                this.formulaire_csrf_token = document.querySelector('meta[name="formulaire-csrf"]').getAttribute('content');
            }
        },
        setFormulaireCsrf(csrf) {
            if(csrf) {
                localStorage.setItem('formulaire_csrf_token', csrf);
                this.formulaire_csrf_token = csrf;
            }
        },
        getFormulaireCsrf() {
            let token = localStorage.getItem('formulaire_csrf_token');
            if (typeof localStorage === 'undefined' || token === 'undefined') {
                this.initFormulaireCsrf();
            }
            return localStorage.getItem('formulaire_csrf_token');
        },
        initAdminCsrf() {
            if(document.querySelector('meta[name="admin-csrf"]').getAttribute('content')) {
                localStorage.setItem("admin_csrf_token", document.querySelector('meta[name="admin-csrf"]').getAttribute('content'));
                this.admin_csrf_token = document.querySelector('meta[name="admin-csrf"]').getAttribute('content');
            }
        },
        setAdminCsrf(csrf) {
            if(csrf) {
                localStorage.setItem('admin_csrf_token', csrf);
                this.admin_csrf_token = csrf;
            }
        },
        getAdminCsrf() {
            let token = localStorage.getItem('admin_csrf_token');
            if (typeof localStorage === 'undefined' || token === 'undefined') {
                this.initAdminCsrf();
            }
            return localStorage.getItem('admin_csrf_token');
        },
        initUserCsrf() {
            if(document.querySelector('meta[name="user-csrf"]').getAttribute('content')) {
                localStorage.setItem("user_csrf_token", document.querySelector('meta[name="user-csrf"]').getAttribute('content'));
                this.user_csrf_token = document.querySelector('meta[name="user-csrf"]').getAttribute('content');
            }
        },
        setUserCsrf(csrf) {
            if(csrf) {
                localStorage.setItem('user_csrf_token', csrf);
                this.user_csrf_token = csrf;
            }
        },
        getUserCsrf() {
            let token = localStorage.getItem('user_csrf_token');
            if (typeof localStorage === 'undefined' || token === 'undefined') {
                this.initUserCsrf();
            }
            return localStorage.getItem('user_csrf_token');
        },
        initPosCsrf() {
            if(document.querySelector('meta[name="pos-csrf"]').getAttribute('content')) {
                localStorage.setItem("pos_csrf_token", document.querySelector('meta[name="pos-csrf"]').getAttribute('content'));
                this.pos_csrf_token = document.querySelector('meta[name="pos-csrf"]').getAttribute('content');
            }
        },
        setPosCsrf(csrf) {
            if(csrf) {
                localStorage.setItem('pos_csrf_token', csrf);
                this.pos_csrf_token = csrf;
            }
        },
        getPosCsrf() {
            let token = localStorage.getItem('pos_csrf_token');
            if (typeof localStorage === 'undefined' || token === 'undefined') {
                this.initPosCsrf();
            }
            return localStorage.getItem('pos_csrf_token');
        },
    },
});
