/**
 * Inject Axios into Vue component
 */

import { axios } from '../utils';
import router from '../router';
export default (app) => {
  app.config.globalProperties.$axios = axios;
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
  );
};
