import {
  registerService,learnersInformationsService
} from '../services';

export default (app) => {
  const services = {
    registerResources: registerService,
    learnersInformationsResources:learnersInformationsService
  };
  app.config.globalProperties.$services = services;
};
