<template>
  <div id="wrapper">
    <v-toolbar>
      <div class="language-select">
        <v-select
            v-model="langSelected" label="" :items="optionLocal" variant="underlined"
            @update:model-value="setLocale(langSelected)"></v-select>
      </div>


      <div class="v-list-item__content--logo">
        <img src="@images/logo.svg" alt="logo dior"/>
      </div>

    </v-toolbar>
    <div class="container-fluid space-container">
      <div class="auth-row">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import {useCsrfStore} from '@/store/csrfStore';

export default {
  name: 'RegisterForm',
  data() {
    return {
      navigLanguage: navigator.language.split('-')[0],
      langSelected: '',
      optionLocal: ['fr', 'en'],
      arrayLocal: [],
      csrcStore: null
    }

  },
  created() {
    this.csrcStore = useCsrfStore();
    this.csrcStore.initFormulaireCsrf();
    this.csrcStore.initAdminCsrf();
    this.csrcStore.initUserCsrf();
    this.csrcStore.initPosCsrf();

    if (this.navigLanguage == 'fr') {
      this.langSelected = 'fr';
      this.$route.params.lang = 'fr';
      this.$i18n.locale = 'fr';

    } else if (this.navigLanguage == 'en') {
      this.langSelected = 'en';
      this.$route.params.lang = 'en';
      this.$i18n.locale = 'en';
    } else {
      this.langSelected = 'en';
      this.$route.params.lang = 'en';
      this.$i18n.locale = 'en';
    }

    this.arrayLocal = this.optionLocal;
    this.optionLocal = this.optionLocal.filter((item) => item != this.langSelected)
    this.submitLang(this.$i18n.locale);
  },
  methods: {
    submitLang(lang) {
      const options = {
        'formulaire_csrf_token': this.csrcStore.getFormulaireCsrf(),
        'lang': lang === 'fr' ? 'fr-FR' : 'en-US',
        'request': 'submit_lang'
      };
      this.$services.registerResources.SubmitRegisterForm(options).then((response) => {
        this.$store.commit("SET_legal_url", response.data.legal_url);
        this.csrcStore.setFormulaireCsrf(response.data.formulaire_csrf_token);
      })
          .catch(({error}) => {
            console.log(error)
          })
    },
    setLocale(locale) {
      this.$i18n.locale = locale
      localStorage.setItem('lang', locale);
      this.optionLocal = this.optionLocal.filter((item) => item != locale);
      if (this.langSelected !== locale) {
        this.optionLocal = this.arrayLocal.filter((item) => item == this.langSelected);
      } else {
        this.optionLocal = this.arrayLocal.filter((item) => item !== this.langSelected);
      }
      this.$router.push({
        params: {lang: locale}
      })
      this.submitLang(locale)
    }

  }
}
</script>

<style lang="scss" scoped></style>
