import Resource from './resource';

class learnersInformation extends Resource {
  constructor() {
    super('');
  }
  ListUser(options) {
    return this.postUser('users/hints',options);
  }
  UserById(options){
    return this.postUser('users/moderate',options);
  }
  FetchUser(options){
    return this.postUser('users',options);
  }
  UpdateUser(options){
    return this.postUser('users/update',options);
  }
  GetPointAvailable(options){
    return this.postUser('users/points/balance',options);
  }
  TransfertAmount(options){
    return this.postUser('users/points/transfer',options);
  }
  PosSalesList(options){
    return this.postUser('pos/list',options);
  }
  PosSalesHintList(options) {
    return this.postUser('pos/list/hints',options);
  }
  ListManagment(options){
    return this.post('admin/users/search',options);
  }
  exportUser(options){
    return this.post('admin/users/search/export',options);
  }
  exportPos(options){
    return this.post('pos/list/export',options);
  }
  importUser(options){
    return this.post('admin/import',options);
  }
}
export default new learnersInformation();
