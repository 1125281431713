<template>
  <v-card>
    <v-layout>
      <v-app-bar>
        <v-app-bar-nav-icon
variant="text" :class="drawer ? 'open' : 'close'"
          @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <div class="v-list-item__content--logo">
          <img src="@images/logo.svg" alt="logo dior" />
        </div>
        <div class="language-select">
          <v-select
v-model="langSelected" label="" :items="optionLocal" variant="underlined"
            @update:model-value="setLocale(langSelected)"></v-select>
        </div>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" location="top" temporary>
        <p>Menu</p>
        <v-list>
          <v-list-item
v-for="navLink in localizedItems" :key="navLink.title" :title="navLink.title"
            :to="navLink.href"></v-list-item>
        </v-list>
      </v-navigation-drawer>

    </v-layout>
  </v-card>
</template>

<script>

export default {
  data: () => ({
    drawer: null,
    group: null,
    lang: '',
    navigLanguage: navigator.language.split('-'),
    langSelected: '',
    optionLocal: ['fr', 'en'],
    arrayLocal: [],
    itemsBase: [
      {
        title_en: 'My points of sales',
        title_fr: 'Mes points de vente',
        value: 'my-points-sales',
        href: '/my-points-sales',
      },
      {
        title_en: 'Learners waiting for moderation',
        title_fr: 'En attente de validation',
        value: 'moderation',
        href: '/learners-moderation',
      },
      {
        title_en: 'Team management',
        title_fr: "Gestion de l'équipe",
        value: 'teamManager',
        href: '/team-manager',
      },
      {
        title_en: 'Archived learners',
        title_fr: 'Apprenants archivés',
        value: 'archived-learners',
        href: '/archived-learners',
      },
    ],
  }),
  computed: {
    // Dynamically compute the items titles based on the selected language
    localizedItems() {
      return this.itemsBase.map(item => ({
        ...item,
        title: this.langSelected === 'fr' ? item.title_fr : item.title_en
      }));
    }
  },

  watch: {
    group() {
      this.drawer = false
    },
  },
  created() {
    // this.navigLanguage = this.navigLanguage[0];

    this.navigLanguage = document.documentElement.lang || 'fr';

    if (!localStorage.getItem('isSetLang')) {

      if (this.navigLanguage == 'fr') {
        this.langSelected = 'fr';
        this.$route.params.lang = 'fr';
        this.$i18n.locale = 'fr';
        localStorage.setItem('lang', 'fr');
      }
      else if (this.navigLanguage == 'en') {
        this.langSelected = 'en';
        this.$route.params.lang = 'en';
        this.$i18n.locale = 'en';
        localStorage.setItem('lang', 'en');
      }
      else {
        this.langSelected = 'en';
        this.$route.params.lang = 'en';
        this.$i18n.locale = 'en';
        localStorage.setItem('lang', 'en');
      }

      this.lang = this.$route.params.lang == 'fr' ? 'fr' : 'en'
    } else {
      this.langSelected = localStorage.getItem('lang')
      this.lang = localStorage.getItem('lang');
      this.$route.params.lang = this.lang;
      this.$i18n.locale = this.lang;
    }
    this.arrayLocal = this.optionLocal;
    this.optionLocal = this.optionLocal.filter((item) => item != this.langSelected)

  },
  methods: {

    setLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem('isSetLang', true);
      localStorage.setItem('lang', locale);
      this.optionLocal = this.optionLocal.filter((item) => item != locale);
      if (this.langSelected !== locale) {
        this.optionLocal = this.arrayLocal.filter((item) => item == this.langSelected);
      } else {
        this.optionLocal = this.arrayLocal.filter((item) => item !== this.langSelected);
      }
      this.$router.push({
        params: { lang: locale }
      })

    }
  }
}
</script>