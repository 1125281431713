export default [
  {
    name: 'registration-pos',
    path: '',
    meta: { requireAuth: false },
    component: () => import('../views/register/RegistrationPosView.vue'),
  },
  {
    name: 'registration-profile',
    path: 'registration-profile',
    meta: { requireAuth: false },
    component: () => import('../views/register/RegistrationProfileView.vue'),
  },
  {
    name: 'accept-account-creation',
    path: 'accept-account-creation',
    meta: { requireAuth: false },
    component: () => import('../views/account-creation/acceptAccountCreation.vue'),
  }
];
