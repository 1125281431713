<template>
  <div id="wrapper-menu-interne" >
    <the-menu></the-menu>
    <div class="container-fluid space-container">
      <div class="row">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import {useCsrfStore} from '@/store/csrfStore';
import TheMenu from '@component/navigation/TheMenu.vue';
export default {
name:'AppLayout',
components:{TheMenu},
data() {
    return {
      navigLanguage:navigator.language || navigator.userLanguage,
      langSelected: '',
      csrcStore: null
    }

},
created() {
  localStorage.setItem("myPosList",null);
  localStorage.setItem("userList",null);
  this.csrcStore = useCsrfStore();
  this.csrcStore.initFormulaireCsrf();
  this.csrcStore.initAdminCsrf();
  this.csrcStore.initUserCsrf();
  this.csrcStore.initPosCsrf();

  if(this.navigLanguage=='fr-FR',this.navigLanguage=='fr'){
          this.langSelected='fr';
          this.$route.params.lang='fr';

        }
        else if(this.navigLanguage=='en-US' || this.navigLanguage=='en'){
          this.langSelected='en';
          this.$route.params.lang='en';
        }
        else{
          this.langSelected='en';
          this.$route.params.lang='en';
        }
       
       
        if (this.$route.params.lang == 'en'){

        }
          this.$i18n.locale=this.$route.params.lang;
},
methods: {
  

}
}
</script>

<style lang="scss" scoped></style>
