/**
 * Resource class
 **/

import { axios } from '../utils';

 const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

export default class Resource {
  constructor(endpoint) {
    this.endpoint = endpoint;
  }
  getAll(options) {
    let url = `${API_BASE_URL}/${this.endpoint}`;
    return axios.get(url, options);
  }
  get(id, options) {
    let url = `${API_BASE_URL}/${this.endpoint}`;
    if (typeof id === 'object') {
      options = id;
    } else if (id !== undefined && this.endpoint) {
      url += `/${id}`;
    } else if (!this.endpoint) {
      url = `/${id}`;
    }
    console.log(options);
    return axios.get(url, options);
  }

  post(urls, options) {
    const url =`${API_BASE_URL}/${this.endpoint}${urls}`;
    return axios.post(url, options);
  }

  postForm(urls,options){
    const url = `${API_BASE_URL}/${this.endpoint}${urls}`;
     return axios.post(url, options);
  }
  postUser(urls,options){
    const url = `${API_BASE_URL}/${this.endpoint}${urls}`;
    return axios.post(url, options);
  }
  postAuth(urls, options) {
    const url = `${API_BASE_URL}/${this.endpoint}${urls}`;
    return axios.post(url, options);
  }
  put(id, options) {
    const url = `${API_BASE_URL}/${this.endpoint}${id}`;
    return axios.put(url, options);
  }

  patch(id, options) {
    const url = `${API_BASE_URL}/${this.endpoint}${id}`;
    return axios.patch(url, options);
  }

  delete(id, options) {
    const url = `${API_BASE_URL}/${this.endpoint}${id}`;
    return axios.delete(url, options);
  }
}
